import React, {
  useReducer,
  useContext,
  useEffect,
  useCallback,
  useState
} from 'react'
import {OptionsObject, SnackbarKey, SnackbarMessage} from 'notistack'
import config from 'src/config'
import Employee from 'src/entities/Employee'
import {Logger, UserAgentApplication} from 'msal'

type EnqueueSnackbar = (
  message: SnackbarMessage,
  options?: OptionsObject | undefined
) => SnackbarKey

interface AuthContextValue {
  user: Employee | null
  logout: () => void
  login: (email: string, enqueueSnackbar: EnqueueSnackbar) => Promise<void>
  isLoggingIn: boolean
  isFetchingUser: boolean
  isAuth: boolean
  roles: string | null
  fetchWithUser: (
    endpoint: RequestInfo,
    options?: RequestInit,
    scopes?: string[]
  ) => Promise<Response>
  loginError: string
  shouldLogout: boolean
}

interface State {
  isAuth: boolean
  roles: string | null
  isLoggingIn: boolean
  isFetchingUser: boolean
  user: Employee | null
  loginError: string
  shouldLogout: boolean
}

type Action =
  | {type: 'DID_LOGOUT'}
  | {type: 'WILL_LOGIN'}
  | {type: 'DID_LOGIN'; data: Employee}
  | {type: 'DID_LOGIN_ERROR'; data: string}
  | {type: 'SHOULD_LOGOUT'; data: string}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'DID_LOGOUT':
      return {...state, isAuth: false, user: null}
    case 'WILL_LOGIN':
      return {...state, isLoggingIn: true, loginError: ''}
    case 'DID_LOGIN':
      return {
        ...state,
        isAuth: true,
        roles: action.data.employeeID.toString(),
        isFetchingUser: true,
        user: action.data,
        isLoggingIn: false
      }
    case 'DID_LOGIN_ERROR':
      return {
        ...state,
        isLoggingIn: false,
        isAuth: false,
        loginError: action.data
      }
    case 'SHOULD_LOGOUT':
      return {
        ...state,
        shouldLogout: true,
        isAuth: false,
        roles: null,
        isLoggingIn: false
      }
    default:
      return state
  }
}

const initialState: State = {
  isAuth: false,
  roles: null,
  isLoggingIn: false,
  isFetchingUser: false,
  user: null,
  loginError: '',
  shouldLogout: false
}

const scope = {
  graph: ['user.read'],
  api: ['api://' + config.AADConfig.clientId + '/User.Read']
}

const AuthContext = React.createContext({} as AuthContextValue)

const requestState = JSON.stringify({
  redirectUrl:
    typeof window !== 'undefined' ? window.location.origin + '/msal.html' : ''
})

function AuthContextProvider({children}: any) {
  const [msal] = useState(() => {
    return new UserAgentApplication({
      auth: {
        redirectUri: config.AADRedirectUrl,
        postLogoutRedirectUri: window.location.href,
        ...config.AADConfig
      },
      cache: {cacheLocation: 'localStorage'},
      system: {
        logger: new Logger((level, message) => {
          if (!config.isProduction) console.log('[msal]', level, message)
        })
      }
    })
  })

  const employeeId = localStorage.getItem('employeeId')
  const [
    {
      isAuth,
      roles,
      isLoggingIn,
      isFetchingUser,
      user,
      loginError,
      shouldLogout
    },
    dispatch
  ] = useReducer(reducer, {
    ...initialState,
    roles: employeeId,
    isFetchingUser: !!employeeId,
    isAuth: !!employeeId
  })

  const logout = useCallback(() => {
    localStorage.removeItem('employeeId')
    dispatch({type: 'DID_LOGOUT'})
  }, [])

  const getAccessToken = useCallback(
    async (scopes: string[]): Promise<string> => {
      const tokenrequest = {
        scopes: scopes,
        state: requestState
      }
      try {
        // Get the access token silently
        // If the cache contains a non-expired token, this function
        // will just return the cached token. Otherwise, it will
        // make a request to the Azure OAuth endpoint to get a token
        var silentResult = await msal.acquireTokenSilent(tokenrequest)
        return silentResult.accessToken
      } catch (err: any) {
        // If a silent request fails, it may be because the user needs
        // to login or grant consent to one or more of the requested scopes
        if (
          err.name === 'InteractionRequiredAuthError' ||
          isInteractionRequired(err)
        ) {
          try {
            var interactiveResult = await msal.acquireTokenPopup(tokenrequest)
            return interactiveResult.accessToken
          } catch {
            dispatch({type: 'SHOULD_LOGOUT', data: err})
            return ''
          }
        } else {
          dispatch({type: 'SHOULD_LOGOUT', data: err})
          return ''
        }
      }
    },
    [msal]
  )

  const fetchWithUser = useCallback(
    async (
      endpoint: RequestInfo,
      options: RequestInit = {},
      scopes: string[] = scope.api
    ): Promise<Response> => {
      var accessToken = await getAccessToken(scopes)
      const headers = {
        ...options.headers,
        Authorization: `Bearer ${accessToken}`
      }
      const mergedOpts = {...options, headers}

      return fetch(endpoint, mergedOpts).then(response => {
        if (!response.ok) {
          return Promise.reject(response)
        }
        return response
      })
    },
    [getAccessToken]
  )

  const login = useCallback(
    async (email: string, enqueueSnackbar: EnqueueSnackbar) => {
      dispatch({type: 'WILL_LOGIN'})
      try {
        const user: Employee = await fetchWithUser(
          config.apiUrl + `/Employee/GetEmployeeByEmail/${email}`
        )
          .then(res => res.json())
          .catch((e: Response) => {
            if (e.status === 404) {
              throw new Error(e.status.toString())
            } else {
              enqueueSnackbar('Internal server error', {
                variant: 'error'
              })
              throw new Error(e.statusText)
            }
          })
        localStorage.setItem('employeeId', user.employeeID.toString())
        dispatch({type: 'DID_LOGIN', data: user})
      } catch (err: any) {
        if (err.message === '404') throw err
        dispatch({type: 'DID_LOGIN_ERROR', data: err.errorMessage})
      }
    },
    [fetchWithUser]
  )

  const getUserProfile = useCallback(async () => {
    try {
      const user: Employee = await fetchWithUser(
        config.apiUrl + `/Employee/GetEmployee/${employeeId}`
      )
        .then(res => res.json())
        .catch((e: Response) => {
          throw new Error(e.statusText)
        })
      dispatch({type: 'DID_LOGIN', data: user})
    } catch (err: any) {
      dispatch({type: 'SHOULD_LOGOUT', data: err.errorMessage})
    }
  }, [employeeId, fetchWithUser])

  const isInteractionRequired = (error: Error): boolean => {
    if (!error.message || error.message.length <= 0) {
      return false
    }

    return (
      error.message.indexOf('consent_required') > -1 ||
      error.message.indexOf('interaction_required') > -1 ||
      error.message.indexOf('login_required') > -1
    )
  }

  useEffect(() => {
    if (isAuth) getUserProfile()
  }, [isAuth, getUserProfile])

  const value: AuthContextValue = {
    user,
    isAuth,
    roles,
    login,
    logout,
    isLoggingIn,
    isFetchingUser,
    fetchWithUser: fetchWithUser,
    loginError,
    shouldLogout
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = () => useContext(AuthContext)

export {AuthContextProvider, useAuth}
