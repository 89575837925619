import React, {useCallback, useEffect, useState, FormEvent, useRef} from 'react'
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material'
import config from 'src/config'
import {useAuth} from 'src/contexts/Auth'
import Track from 'src/entities/Track'
import CuiProgressButton from 'src/components/custom/CuiProgressButton'
import Language from 'src/entities/Language'
import EmployeeSHLCycleLanguage, {
  NeedSeferEnum
} from 'src/entities/EmployeeSHLCycleLanguage'
import {useSnackbar} from 'notistack'
import ShlCycle from 'src/entities/ShlCycle'
import isDateAfterToday from 'src/utils/dateHelper'
import CycleWrapper from 'src/components/shl/cycle/CycleWrapper'
import CycleSuccessPage from 'src/components/shl/cycle/CycleSuccessPage'
import {StatusSuccessPage} from 'src/settings/type'
import 'src/components/shl/cycle/cycle.css'

export default function RegisterSHLCyclePage() {
  const [employeeSHLCycle, setEmployeeSHLCycle] =
    useState<EmployeeSHLCycleLanguage>({
      trackID: 0,
      languageID: 0
    })
  const [loading, setLoading] = useState<boolean>(true)
  const [shlCycle, setShlCycle] = useState<ShlCycle>()
  const [successPageStatus, setSuccessPageStatus] = useState<StatusSuccessPage>(
    StatusSuccessPage.Hidden
  )
  const [examinationTypeList, setExaminationTypeList] = useState<Track[]>([])
  const [languageList, setLanguageList] = useState<Language[]>([])
  const examinationTypeNameRef = useRef<string>()
  const {fetchWithUser, employee, logout} = useAuth()
  const {enqueueSnackbar} = useSnackbar()

  const getLanguageList = useCallback(() => {
    fetchWithUser(config.apiUrl + '/CodeTable/GetLanguageList')
      .then(res => res.json())
      .then((data: Language[]) => {
        setLanguageList(data)
      })
  }, [fetchWithUser])

  const getExaminationTypeList = useCallback(() => {
    return fetchWithUser(config.apiUrl + `/Examination/GetAllTrackList`)
      .then(res => res.json())
      .then((data: Track[]) => {
        setExaminationTypeList(data)
        return data
      })
      .catch(e => {
        console.log(e)
        enqueueSnackbar('Internal server error, Please try later', {
          variant: 'error'
        })
        return []
      })
  }, [fetchWithUser, enqueueSnackbar])

  const getEmployeeToSHLCycle = useCallback(async () => {
    if (!employee?.employeeID) return
    let currentShlcycle: ShlCycle | null = null
    fetchWithUser(config.apiUrl + '/SHLCycle/GetLastSHLCycle')
      .then(res => res.json())
      .then((data: {[key: string]: string}) => {
        currentShlcycle = {
          shlCycleID: +data.shlCycleID,
          shlCycleName: data.shlCycleName,
          cycleNumber: +data.cycleNumber,
          startDate: new Date(data.startDate),
          endDate: new Date(data.endDate),
          lastUpdateDate: new Date(data.lastUpdateDate)
        }
        setShlCycle(currentShlcycle)
      })
    const examinationTypeList = await getExaminationTypeList()
    fetchWithUser(
      config.apiUrl + `/SHLCycle/GetEmployeeLastSHLCycle/${employee.employeeID}`
    )
      .then(res => res.json())
      .then((data: EmployeeSHLCycleLanguage) => {
        if (!currentShlcycle) return
        setSuccessPageStatus(
          isDateAfterToday(currentShlcycle.lastUpdateDate)
            ? StatusSuccessPage.Hidden
            : StatusSuccessPage.Closed
        )
        setEmployeeSHLCycle(data)
        examinationTypeNameRef.current = examinationTypeList.find(
          et => et.trackID === data.trackID
        )?.trackName
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [fetchWithUser, getExaminationTypeList, employee?.employeeID])

  useEffect(() => {
    getLanguageList()
  }, [getLanguageList])

  useEffect(() => {
    getEmployeeToSHLCycle()
  }, [getEmployeeToSHLCycle])

  const selectLanguage = (value: number) => {
    const examinationTypeID = examinationTypeList.find(
      et =>
        et.trackName === examinationTypeNameRef.current &&
        et.languageID === value
    )?.trackID
    setEmployeeSHLCycle(prev => ({
      ...prev,
      trackID: examinationTypeID ?? prev.trackID,
      languageID: value
    }))
  }

  const submitForm = (e: FormEvent) => {
    if (!employee) return
    setLoading(true)
    e.preventDefault()
    employeeSHLCycle.employeeID = employee.employeeID.toString()
    fetchWithUser(
      config.apiUrl +
        `/SHLCycle/AddEmployeesToSHLCycleByTrackId?employeeID=${
          employeeSHLCycle.employeeID
        }&trackId=${employeeSHLCycle.trackID}&isNeedSefer=${
          employeeSHLCycle.needSeferID !== NeedSeferEnum.NoNeed
        }`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(res => res.json())
      .then(() => {
        if (!shlCycle) return
        setSuccessPageStatus(
          isDateAfterToday(shlCycle.lastUpdateDate)
            ? StatusSuccessPage.EnableUpdate
            : StatusSuccessPage.Closed
        )
        setLoading(false)
      })
      .catch(() => {
        enqueueSnackbar('Error, Please try again', {variant: 'error'})
      })
  }

  if (successPageStatus) {
    return (
      <CycleSuccessPage
        isEnableUpdate={successPageStatus === StatusSuccessPage.EnableUpdate}
        onClose={() => setSuccessPageStatus(StatusSuccessPage.Hidden)}
      />
    )
  }

  return (
    <Stack bgcolor="primary.main">
      <CycleWrapper>
        <Stack
          m="auto"
          pt="40px"
          pl="14px"
          mb="15px"
          spacing={3}
          bgcolor="white"
          minWidth="450px"
          width="95%"
          maxWidth={1000}
          mt={0}
        >
          {loading ? (
            <CircularProgress
              size={48}
              sx={{
                color: 'primary.main',
                position: 'absolute',
                top: '65%',
                left: '50%',
                marginTop: -1.5,
                marginLeft: -1.5
              }}
            />
          ) : (
            <form onSubmit={form => submitForm(form)}>
              <Stack spacing={3} width={800} maxWidth={'100%'} m="auto">
                <Stack>
                  <Typography
                    fontWeight="bold"
                    fontSize="x-large"
                    bgcolor="#FFDB2B"
                    display="inline-block"
                    width="388px"
                    p="5px"
                    my="10px"
                  >
                    REGISTER FOR THE 15TH CYCLE
                  </Typography>
                  {employee && (
                    <>
                      <Typography fontWeight="bold" fontSize="large">
                        Hello, {employee.firstName} {employee.lastName}
                      </Typography>
                      <Typography fontWeight="bold" fontSize="small">
                        Employee number: {employee.employeeID}
                      </Typography>
                      {employeeSHLCycle.employeeID && (
                        <Typography mt={1} fontStyle={'oblique'}>
                          You are registered already for the 15th cycle!!
                        </Typography>
                      )}
                    </>
                  )}
                </Stack>

                {languageList.length > 0 && (
                  <Stack direction="row" spacing={2}>
                    <FormControl sx={{paddingInlineStart: 1.75}} required>
                      <FormLabel
                        id="language-radio-group-label"
                        sx={{fontWeight: 'bold', color: 'primary.main'}}
                      >
                        What language would you like to take the test in?
                      </FormLabel>
                      <FormHelperText
                        sx={{
                          marginLeft: 0,
                          color: 'primary.main',
                          fontWeight: 500
                        }}
                      >
                        Its advised to take the tests in the same language that
                        you learn the halochos in
                      </FormHelperText>
                      <RadioGroup
                        aria-labelledby="language-radio-group-label"
                        value={employeeSHLCycle.languageID}
                        name="testLanguage"
                        onChange={(e, value) => selectLanguage(+value)}
                      >
                        {languageList.map(l => (
                          <FormControlLabel
                            key={l.key}
                            value={l.key}
                            control={<Radio required />}
                            label={l.value}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Stack>
                )}
                {examinationTypeList.length > 0 &&
                  employeeSHLCycle.languageID !== 0 && (
                    <>
                      <Stack direction="row" spacing={2}>
                        <FormControl sx={{paddingInlineStart: 1.75}} required>
                          <FormLabel
                            id="examination-type-radio-group-label"
                            sx={{fontWeight: 'bold', color: 'primary.main'}}
                          >
                            Choose a learning Track:
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="examination-type-radio-group-label"
                            value={employeeSHLCycle.trackID}
                            name="testLevel"
                            onChange={e => {
                              examinationTypeNameRef.current =
                                examinationTypeList.find(
                                  et => et.trackID === +e.target.value
                                )?.trackName
                              setEmployeeSHLCycle(prev => ({
                                ...prev,
                                trackID: +e.target.value
                              }))
                            }}
                          >
                            {examinationTypeList
                              .filter(
                                et =>
                                  et.languageID === employeeSHLCycle.languageID
                              )
                              .map(et => (
                                <FormControlLabel
                                  key={et.trackID}
                                  value={et.trackID}
                                  control={<Radio required />}
                                  label={et.trackName}
                                />
                              ))}
                          </RadioGroup>
                        </FormControl>
                      </Stack>
                      {employeeSHLCycle.languageID !== 0 &&
                        employeeSHLCycle.trackID !== 0 && (
                          <Stack direction="row" spacing={2}>
                            <FormControl
                              sx={{paddingInlineStart: 1.75}}
                              required
                            >
                              <FormLabel
                                id="sefer-radio-group-label"
                                sx={{fontWeight: 'bold', color: 'primary.main'}}
                              >
                                Do you need a sefer?
                              </FormLabel>
                              <RadioGroup
                                aria-labelledby="sefer-radio-group-label"
                                value={
                                  (employeeSHLCycle.needSeferID &&
                                    employeeSHLCycle.needSeferID !==
                                      NeedSeferEnum.NoNeed) ??
                                  ''
                                }
                                name="isNeedSefer"
                                onChange={(e, value) => {
                                  setEmployeeSHLCycle(prev => ({
                                    ...prev,
                                    needSeferID:
                                      value === 'true'
                                        ? NeedSeferEnum.Need
                                        : NeedSeferEnum.NoNeed
                                  }))
                                }}
                              >
                                <FormControlLabel
                                  key={0}
                                  value={false}
                                  control={<Radio required />}
                                  label="No"
                                />
                                <FormControlLabel
                                  key={1}
                                  value={true}
                                  control={<Radio required />}
                                  label="Yes"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Stack>
                        )}
                    </>
                  )}
              </Stack>

              <Stack
                direction="row"
                spacing={2}
                m="25px"
                justifyContent={'space-between'}
              >
                <CuiProgressButton
                  variant="contained"
                  sx={{
                    backgroundColor: 'transparent !important',
                    color: 'black',
                    height: '48px',
                    boxShadow: 'none'
                  }}
                  onClick={logout}
                >
                  Cancel
                </CuiProgressButton>
                <CuiProgressButton
                  color="primary"
                  type="submit"
                  variant="contained"
                  m="auto"
                  sx={{
                    width: '400px',
                    height: '58px'
                  }}
                  loading={loading}
                >
                  Save
                </CuiProgressButton>
              </Stack>
            </form>
          )}
        </Stack>
      </CycleWrapper>
    </Stack>
  )
}
