import {Stack, Tooltip} from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {
  ListItemStyle as ListItem,
  ListItemIconStyle as ListItemIcon
} from 'src/components/shl/Test/ListItem'
import {ReactComponent as TestIcon} from 'src/components/shl/Test/assets/ic_invoice.svg'
import HowToVoteIcon from '@mui/icons-material/HowToVote'
import IconAmountBox from 'src/components/shl/IconAmountBox'
import ExaminationToEmployee, {
  submissionStatusEnum
} from 'src/entities/ExaminationToEmployee'
import AlarmOnIcon from '@mui/icons-material/AlarmOn'
import LockIcon from '@mui/icons-material/Lock'

interface TestItemProps {
  index: number
  currentTest: ExaminationToEmployee
  name: string
  raffleTicket: number | undefined
  isWinnersLottery: boolean | undefined
  submissionStatus: submissionStatusEnum | undefined
  setSelectedTest: React.Dispatch<React.SetStateAction<ExaminationToEmployee>>
  selectedTest: ExaminationToEmployee
}

export default function TestItem({
  index,
  currentTest,
  name,
  raffleTicket,
  isWinnersLottery,
  submissionStatus,
  setSelectedTest,
  selectedTest
}: TestItemProps) {
  return (
    <ListItem
      key={index}
      onClick={() => {
        setSelectedTest(currentTest)
      }}
      activeRoot={selectedTest === currentTest}
      sx={{alignItems: 'center'}}
      disabled={!currentTest.form && !currentTest.examinationUrl}
    >
      <Stack direction="row" width="100%">
        <ListItemIcon>
          <TestIcon
            fill={selectedTest === currentTest ? '#03787c' : 'grey.600'}
          />
        </ListItemIcon>
        <Stack sx={{alignSelf: 'flex-start'}}>
          {' '}
          {index === 0 ? 'Current' : name}
        </Stack>
      </Stack>
      {submissionStatus && submissionStatus !== submissionStatusEnum.Lack && (
        <IconAmountBox
          amount={
            <Tooltip
              children={
                <Stack direction={'row'} sx={{mr: 4}}>
                  {isWinnersLottery && `(`}
                  {(raffleTicket === 0 || raffleTicket) && <HowToVoteIcon />}
                  {raffleTicket}
                  {isWinnersLottery && `)`}
                </Stack>
              }
              title={'Tickets earned '}
            />
          }
        />
      )}

      <ListItemIcon>
        {submissionStatus &&
          submissionStatus === submissionStatusEnum.OnTime && (
            <Tooltip
              children={
                <AlarmOnIcon
                  sx={{color: 'primary.main', mr: 3, alignItems: 'center'}}
                />
              }
              title={'Submission on time'}
            />
          )}
      </ListItemIcon>

      {currentTest.form ? (
        <></>
      ) : currentTest.examinationUrl ? (
        <Stack
          onClick={e => {
            e.stopPropagation()
            window.open(currentTest.examinationUrl, '_blank')
          }}
          sx={{alignItems: 'center', mr: 1}}
        >
          <Tooltip
            children={
              <OpenInNewIcon
                fill={selectedTest === currentTest ? '#03787c' : 'grey.600'}
              />
            }
            title={'Open test in new tab'}
          />
        </Stack>
      ) : (
        <Tooltip
          children={<LockIcon fill={'grey.600'} />}
          title={'Test lock'}
        />
      )}
    </ListItem>
  )
}
