import React, {useCallback, useEffect, useState} from 'react'
import {UserTest} from '@madisoncres/cb-form-creator'
import '@madisoncres/cb-form-creator/dist/index.css'
import {useSnackbar} from 'notistack'
import {alpha, Box, CircularProgress, List, Stack} from '@mui/material'
import {useAuth} from 'src/contexts/Auth'
import {LanguageEnum} from 'src/settings/type'
import logo from 'src/components/shl/Test/assets/logo.png'
import ExaminationToEmployee from 'src/entities/ExaminationToEmployee'
import config from 'src/config'
import TestItem from 'src/components/shl/Test/TestItem'
import {AddFormResult} from 'src/entities/AddFormResult'

export default function TestList() {
  const [testList, setTestList] = useState<ExaminationToEmployee[]>([])
  const [selectedTest, setSelectedTest] = useState<ExaminationToEmployee>(
    testList[0]
  )
  const [loading, setLoading] = useState<boolean>(true)
  const {fetchWithUser, employee} = useAuth()
  const {enqueueSnackbar} = useSnackbar()

  const getExaminationListByEmployeeId = useCallback(() => {
    if (!employee) return
    fetchWithUser(
      `${config.apiUrl}/Examination/GetExaminationListByEmployeeId?empId=${employee.employeeID}&isActiveEmp=true&isBackoffice=false`
    )
      .then(res => res.json())
      .then((data: ExaminationToEmployee[]) => {
        setTestList(data)
        if (!data.length)
          return enqueueSnackbar(
            'There are still no open tests for this cycle',
            {variant: 'warning'}
          )
        setSelectedTest(prev =>
          !prev
            ? data[0]
            : data.find(e => e.examinationOrder === prev.examinationOrder) ??
              data[0]
        )
        setLoading(false)
      })
      .catch(e => {
        enqueueSnackbar(
          e.status === 404
            ? 'You are not active in the program, you do not have permission to enter'
            : 'Error, Please try again',
          {
            variant: e.status === 404 ? 'warning' : 'error'
          }
        )
      })
  }, [fetchWithUser, enqueueSnackbar, employee])

  const onCompleteFormTest = (formResultContent: string, score: number) => {
    const formResult = {
      formID: selectedTest.form?.formID,
      examinationID: selectedTest.examinationID,
      content: formResultContent,
      employeeID: employee?.employeeID,
      score: score
    } as AddFormResult
    return fetchWithUser(`${config.apiUrl}/Form/AddFormResult`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(formResult)
    })
      .then(() => {
        getExaminationListByEmployeeId()
      })
      .catch(e => {
        throw e
      })
  }

  useEffect(() => {
    getExaminationListByEmployeeId()
  }, [employee, getExaminationListByEmployeeId])

  return (
    <Box height="100%" style={{overflow: 'hidden'}}>
      {loading ? (
        <CircularProgress
          size={48}
          sx={{
            color: 'primary.main',
            position: 'absolute',
            top: '65%',
            left: '50%',
            marginTop: -1.5,
            marginLeft: -1.5
          }}
        />
      ) : (
        testList && (
          <Stack
            direction="row"
            height="100%"
            justifyContent="start"
            sx={{
              direction:
                testList[0].track.languageID === LanguageEnum.English
                  ? 'ltr'
                  : 'rtl'
            }}
          >
            <List
              sx={{
                height: '100vh',
                width: 350,
                bgcolor: 'background.paper',
                px: 2,
                overflowY: 'auto'
              }}
            >
              <img
                alt="logo"
                src={logo}
                height="107px"
                style={{paddingBottom: 32, margin: 'auto'}}
              />
              {testList?.map((item, index) => (
                <TestItem
                  key={index}
                  index={index}
                  currentTest={item}
                  name={`Test ${item.examinationOrder}`}
                  raffleTicket={item.loteryTicketsNumber}
                  isWinnersLottery={item.isWinnerLotery}
                  submissionStatus={item.submissionStatus}
                  setSelectedTest={setSelectedTest}
                  selectedTest={selectedTest}
                />
              ))}
            </List>
            {!!selectedTest?.form ? (
              <Stack
                bgcolor={alpha(selectedTest.track?.primeColor, 0.08)}
                width="100%"
                direction="row"
                justifyContent="center"
              >
                <Stack sx={{width: '99%'}} overflow="auto" boxShadow={5}>
                  <UserTest
                    track={selectedTest.track}
                    testContent={selectedTest.form.content}
                    testResult={selectedTest.formResultContent}
                    saveForm={onCompleteFormTest}
                  />
                </Stack>
              </Stack>
            ) : (
              <iframe
                title="Iframe Test"
                src={selectedTest?.examinationUrl}
                style={{
                  border: 'none',
                  width: 'calc(100% - 250px)',
                  height: '99.1%',
                  overflowY: 'auto'
                }}
              />
            )}
          </Stack>
        )
      )}
    </Box>
  )
}